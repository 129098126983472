import axios from 'axios'
import Vue from 'vue'
import apiClient from './apiClient'
import store from '../store'
const CancelToken = axios.CancelToken
const source = CancelToken.source()

apiClient.interceptors.request.use(function (config) {
  config.cancelToken = source.token
  // config.headers['x-trueProfit-App-Version'] = process.env.VUE_APP_VERSION
  // config.headers['x-trueProfit-App-Build-Version'] = process.env.VUE_APP_BUILD_NUMBER
  if (!config.headers?.Authorization) {
    config.headers.Authorization = `Bearer ${store.state.auth.token.token}`
    // config.headers.token = `${store.state.auth.token.token}`
  }
  return config
})

apiClient.interceptors.response.use(null, async function (error) {
  if (axios.isCancel(error)) {
    return null
  } else if (error.response) {
    const errorData = {
      request_url: error.response.config.url,
      request_data: error.response.config.data,
      status_code: error.response.status
    }

    if (error.response.data != null) {
      errorData.response_data = error.response.data
      if (error.response.data.message != null) {
        Vue.prototype.$message.error(error.response.data.message)
        errorData.response_message = error.response.data.message
      }
    }
    // if (process.env.NODE_ENV === 'production') {
    if (error.response.status === 401 || error.response.status === 403) {
      await store.dispatch('auth/account/logout')
      await source.cancel('canceled')
      return null
    }
    // }
  }
  return Promise.reject(error)
})
