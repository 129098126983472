<template>
  <div
    v-if="loading"
    class="w-screen fixed inset-0 h-screen flex items-center justify-center text-neutral-600 text-2xl uppercase bg-white"
  >
    <p v-text="loadingMessage" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AffiliateLoading',
  data () {
    return {
      loadingMessage: 'Loading...'
    }
  },
  computed: {
    ...mapState('loading', ['loading'])
  }
}
</script>
