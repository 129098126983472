<template>
  <el-dialog
    ref="dialog"
    width="440px"
    :visible.sync="isShow"
    :close-on-click-modal="false"
    title="Change Password"
    :close-on-press-escape="false"
    @closed="onClosed"
  >
    <form @submit.prevent="handleSubmit">
      <aff-input-group
        v-model="$v.currentPassword.$model"
        label="Current Password"
        placeholder="Your current password"
        type="password"
        :vuelidate="$v.currentPassword"
      />
      <aff-input-group
        v-model="$v.newPassword.$model"
        label="New Password"
        placeholder="Your new password"
        type="password"
        :vuelidate="$v.newPassword"
      />
      <aff-input-group
        v-model="$v.confirmPassword.$model"
        label="Confirm New Password"
        placeholder="Confirm your new password"
        type="password"
        :vuelidate="$v.confirmPassword"
      />
      <div class="flex justify-between items-center mt-4">
        <AffButton
          text="Change Password"
          :loading="loading"
          class-name="bg-blue-500 text-white flex-1 mr-4"
        />
        <a
          class-name="bg-neutral-50 text-black flex-1"
          class="bg-neutral-50 text-black text-sm text-medium px-4 py-3 rounded flex-1 mt-5 mr-4"
          style="text-align: center; cursor: pointer"
          @click="onClosed"
        >Cancel</a>
      </div>
    </form>
  </el-dialog>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { required, sameAs } from 'vuelidate/lib/validators'
export default {
  name: 'DialogChangePassword',
  data () {
    return this.resetData({})
  },
  validations: {
    currentPassword: {
      required
    },
    newPassword: {
      required
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('newPassword')
    }
  },
  created () {
    this.SET_MEMBER_MENU_ITEM({
      text: 'Change password',
      command: this.show,
      id: 'DialogChangePassword'
    })
  },

  methods: {
    ...mapMutations('ui', ['SET_MEMBER_MENU_ITEM']),
    ...mapActions('auth/account', ['changePassword']),
    resetData (toObject) {
      return Object.assign(toObject || this, {
        isShow: false,
        loading: false,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      })
    },
    async handleSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      } else {
        this.loading = true
        const data = {
          password: this.currentPassword,
          new_pass: this.newPassword
        }
        const response = await this.changePassword(data)
        this.loading = false
        this.$message.info(response)
      }
    },
    async show () {
      this.isShow = true
    },
    onClosed () {
      // reset data
      this.isShow = false
      this.resetData()
    }
  }
}
</script>
