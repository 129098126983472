const state = () => ({
  token: ''
})

const tokenModule = {
  namespaced: true,
  state: state,
  mutations: {
    SET_TOKEN: (state, token) => (state.token = token)
  },
  actions: {}
}

export default tokenModule
