const state = {
  loading: false
}
const mutations = {
  START_LOADING (state) {
    state.loading = true
  },
  STOP_LOADING (state) {
    state.loading = false
  }
}
export default {
  namespaced: true,
  state,
  mutations
}
