<template>
  <div class="group-input">
    <label class="text-xs text-medium text-black">{{ label }}</label>
    <input
      :value="value"
      autocomplete="on"
      :type="type"
      :placeholder="placeholder"
      class="w-full px-4 py-3 border border-neutral-100 rounded text-sm focus:outline-none mt-1"
      :class="{'border-red-500' : vuelidate.$error}"
      @input="handleInput"
    >
    <div
      class="text-xs text-red-500 mt-1"
    >
      <span v-if="!vuelidate.required && vuelidate.$error">
        Please enter your {{ label.toLowerCase() }}
      </span>
      <span v-if="vuelidate.required && !vuelidate.sameAsPassword && label.includes('Confirm')">
        Passwords must be identical.
      </span>
      <span
        v-if="!vuelidate.email && vuelidate.$error && label.includes('Email')"
      >
        Please enter a valid {{ label.toLowerCase() }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AffInputGroup',
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: ''
    },
    vuelidate: {
      type: Object,
      default: () => {}
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style>

</style>
