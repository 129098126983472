import { login, changePassword } from '@/api/account'
import router from '@/router'
import Vue from 'vue'
const state = () => ({
  user: {}
})

const accountModule = {
  namespaced: true,
  state: state,
  mutations: {
    SET_USER: (state, payload) => (state.user = payload)
  },
  actions: {
    async login ({ commit }, formData) {
      try {
        const response = await login(formData)
        commit('SET_USER', response)
        commit('auth/token/SET_TOKEN', response.token, { root: true })
        await router.push('/')
      } catch (error) {
        Vue.prototype.$notify({
          customClass: 'notify',
          title: 'Notification',
          message: error,
          type: 'error'
        })
        return false
      }
    },
    async changePassword ({ commit }, formData) {
      try {
        return await changePassword(formData)
      } catch (error) {
        return 'Can not change password'
      }
    },
    async logout ({ commit }, formData) {
      try {
        commit('auth/token/SET_TOKEN', '', { root: true })
        window.location.reload()
      } catch (error) {
        return false
      }
    }
  }
}

export default accountModule
