import apiClient from './apiClient'
import { LOGIN_ENDPOINT, CHANGE_PASSWORD_ENDPOINT } from './index'
async function login (formData) {
  const response = await apiClient.post(LOGIN_ENDPOINT, formData)
  return response.data
}

async function changePassword (formData) {
  const response = await apiClient.post(CHANGE_PASSWORD_ENDPOINT, formData)
  return response.data
}
export {
  login,
  changePassword
}
