import { fetchTransactions } from '@/api/partner'

const state = () => ({
  transaction: {
    pendingCommission: '',
    totalCommission: ''
  }
})

const fetchTransactionsModule = {
  namespaced: true,
  state: state,
  mutations: {
    SET_PENDING_COMMISSION: (state, payload) => (state.transaction.pendingCommission = '$' + payload),
    SET_TOTAL_COMMISSION: (state, payload) => (state.transaction.totalCommission = '$' + payload)
  },
  actions: {
    async fetchTransactions ({ commit }, token) {
      commit('loading/START_LOADING', null, { root: true })
      try {
        const { data } = await fetchTransactions(token)
        commit('SET_PENDING_COMMISSION', data.pending_commission.toFixed(2))
        commit('SET_TOTAL_COMMISSION', data.total_commission.toFixed(2))
      } catch (error) {
        // todo
      } finally {
        commit('loading/STOP_LOADING', null, { root: true })
      }
    }
  }
}

export default fetchTransactionsModule
