import { fetchReferrals } from '@/api/partner'

const state = () => ({
  referrals: [],
  totalPages: 0,
  name: '',
  link: '',
  report: {
    totalActive: '',
    totalClick: ''
  }
})

const fetchReferralsModule = {
  namespaced: true,
  state: state,
  mutations: {
    SET_LIST_REFERRALS: (state, payload) => (state.referrals = payload),
    SET_TOTAL_ACTIVE: (state, payload) => (state.report.totalActive = payload),
    SET_TOTAL_CLICK: (state, payload) => (state.report.totalClick = payload),
    SET_TOTAL_PAGES: (state, payload) => (state.totalPages = payload),
    SET_NAME: (state, payload) => (state.name = payload),
    SET_LINK_SOURCE: (state, payload) => (state.link = payload)
  },
  actions: {
    async fetchReferrals ({ commit }, token) {
      commit('loading/START_LOADING', null, { root: true })
      try {
        const response = await fetchReferrals(token)
        commit('SET_LIST_REFERRALS', response.list.results)
        commit('SET_TOTAL_PAGES', response.list.total_pages)
        commit('SET_TOTAL_ACTIVE', response.total_active_referrals)
        commit('SET_TOTAL_CLICK', response.total_clicks)
        commit('SET_LINK_SOURCE', response.link_source)
        commit('SET_NAME', response.name)
      } catch (error) {
        // todo
      } finally {
        commit('loading/STOP_LOADING', null, { root: true })
      }
    }
  }
}

export default fetchReferralsModule
