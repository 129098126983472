import token from './modules/token'
import account from './modules/account'
/** @type {import('vuex').Module} */
const authModule = {
  namespaced: true,
  modules: {
    token,
    account
  }
}
export default authModule
