import apiClient from './apiClient'
import { REFERRALS_ENDPOINT, TRANSACTION_ENDPOINT } from './index'
async function fetchReferrals (token) {
  const response = await apiClient.get(REFERRALS_ENDPOINT, { params: token })
  return response.data
}
async function fetchTransactions () {
  const response = await apiClient.get(TRANSACTION_ENDPOINT)
  return response.data
}
export {
  fetchReferrals,
  fetchTransactions
}
