import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Affiliate',
    component: () => import('@/views/Affiliate'),
    beforeEnter (to, from, next) {
      if (!store.state.auth.token.token) next({ name: 'Login' })
      else {
        next()
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login'),
    beforeEnter (to, from, next) {
      if (store.state.auth.token.token) next({ name: 'Affiliate' })
      else {
        next()
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
