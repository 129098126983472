<template>
  <button
    :class="[className, {'bg-opacity-75': loading}]"
    class="flex items-center justify-center w-full px-4 py-3 rounded text-medium mt-5 focus:outline-none"
  >
    <div
      v-if="loading"
      class="aff-spinner"
    >
      <div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div />
    </div>
    <span class="ml-4">{{ text }}</span>
  </button>
</template>

<script>
export default {
  name: 'AffInputGroup',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    }
  }
}
</script>
