import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
import enLang from 'element-ui/lib/locale/lang/en'
import Vuelidate from 'vuelidate'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/style.css'
import '@/assets/vendor/fonts/style.css'
import ElementUI from 'element-ui'
import '@/filters'
import '@/api/interceptors'
import VueClipboard from 'vue-clipboard2'
import '@/components/global'
Vue.use(Vuelidate)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  locale: enLang
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
