import _formatDate from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

const layout = 'yyyy-MM-dd HH:m:s'
function formatDate (date) {
  return _formatDate(parseISO(date), layout)
}
function formatShopName (domain) {
  return domain.replace('.myshopify.com', '')
}

export { formatDate, formatShopName }
