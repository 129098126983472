import Vue from 'vue'
const state = () => ({
  userMenuItems: {}
})

const uiModule = {
  namespaced: true,
  state: state,
  mutations: {
    SET_MEMBER_MENU_ITEM: (state, payload) => (Vue.set(state.userMenuItems, payload.id, payload))
  }
}

export default uiModule
