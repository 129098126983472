import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth/auth'
import ui from './ui/ui'
import partner from './partner/partner'
import loading from './loading'
import { vuexPersist } from '@/services/vuexPersist'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    partner,
    auth,
    ui,
    loading
  },
  plugins: [vuexPersist.plugin]
})
export default store
