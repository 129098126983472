import Vue from 'vue'

import AffInputGroup from './AffInputGroup'
import DialogChangePassword from './DialogChangePassword'
import AffButton from './AffButton'
const comps = {
  AffInputGroup,
  DialogChangePassword,
  AffButton
}
for (const key in comps) Vue.component(key, comps[key])
