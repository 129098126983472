import fetchReferrals from './modules/fetchReferrals'
import fetchTransactions from './modules/fetchTransactions'
/** @type {import('vuex').Module} */
const partnerModule = {
  namespaced: true,
  modules: {
    fetchReferrals,
    fetchTransactions
  }
}

export default partnerModule
